import React from 'react'

function Footer() {
  return (
    <>
        <footer>
            <p>©All Right Reserved Print & Go</p>
        </footer>
    </>
  )
}

export default Footer
