import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import Header from '../header.js';

function PreviewDocument() {

  const location = useLocation();
  const state = location.state;
  const documentData = state?.data;
  const [imageFile, setImageFile] = useState([]);
  const [pdfFile, setPdfFile] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        getFile();
    }, []);

  const getFile = () => {
    const pdfFiles = [];
    const imageFiles = [];

    if (documentData) {
      documentData.documents.forEach(i => {
        const extension = i.split('.').pop().toLowerCase();
        if (extension === 'pdf') {
          pdfFiles.push(`${baseUrl + i}`);
        } else if (extension === 'png' || extension === 'jpeg' || extension === 'jpg') {
          imageFiles.push(`${baseUrl + i}`);
        }
      });

      setPdfFile(prevState => [...prevState, ...pdfFiles]);
      setImageFile(prevState => [...prevState, ...imageFiles]);
    }
  }

  return (
    <>
      <div style={{ padding: "15px" }}>
          <Header />
          <div className='container mt-3 text-center'>
            <h2>Uploaded Documents</h2>
            <div className='show-document mt-5'>
                {imageFile && imageFile.length > 0 &&
                    imageFile.map((i, index) => (
                        <div className="show-file" key={index} onClick={() => window.open(i, '_blank')}>
                            <div className="img-wrap-file">
                                <img src={i} alt="Preview" />
                            </div>
                        </div>
                    ))
                }
                {pdfFile && pdfFile.length > 0 &&
                    pdfFile.map((i, index) => (
                        <div className="show-pdf" key={index} onClick={() => window.open(i, '_blank')}>
                          <Document file={i}>
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                    ))
                }
            </div>
          </div>
      </div>
    </>
  );
}

export default PreviewDocument;
