import React from "react";
import { useNavigate } from 'react-router-dom';
import rightArrow from "../Assets/images/right-arrow.png";
import logo from "../Assets/images/logo.png";

function Header() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <div className="more-navbar">
            <div className="wrapper">
            <img src={logo} style={{ cursor: "pointer" }}
            className="img-fluid logo" alt="brand" onClick={handleClick}/>
            <button className="back-button" onClick={handleClick}>
            <img src={rightArrow} alt="arrow" />Back to home</button>
            </div>
        </div>
    );
}

export default Header;