import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Check from "../../Assets/images/check.png";
import Header from '../header.js';

function Congratulation() {

    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const queryParams = new URLSearchParams(location.search);
    const couponCode = queryParams.get('coupon_code');
    const finalAmount = queryParams.get('final_amount');
    const top_up = queryParams.get('top_up');
    const [stateValue, setStateValue] = useState('');
    const [page, setPage] = useState('');
    const [amount, setAmount] = useState(0);
    const [isTopUp, setIsTopUp] = useState(false);

    useEffect(() => {
        if (couponCode || state?.page === 'coupon') {
            setStateValue(couponCode || state?.coupon);
            setAmount(finalAmount || state?.amount);
            setPage('coupon');
        } else {
            setStateValue(state?.code);
            setPage('document');
        }
        if (top_up == 1 || state?.top_up) setIsTopUp(true);
    }, [state])

    const handleClick = () => {
        navigate('/');
    };

    return (
        <div style={{ padding: "15px" }}>
            <Header />
            <div className="coupon-sec congratulation">
                <div className="wrapper">
                    {page === 'coupon' ?
                        <>
                            <h3 className="center">Coupon Credited, Ready To Print!</h3>
                            {isTopUp ?
                            <>
                                <span className="center">
                                    Top up successful! Amount <b style={{ fontSize: "20px"}}> {amount} </b>
                                    added to coupon code <b style={{ color: 'red', fontSize: "20px" }}>{stateValue}</b>
                                </span>
                            </>
                            :
                            <>
                                <span className="center">Congratulation! You've Unlocked
                                <b style={{ fontSize: "20px"}}> {amount}</b> Worth Of  Printing.</span>
                                <span className="center" style={{ color: 'red' }}>{stateValue}</span>
                            </>
                            }
                        </>
                        :
                        <>
                            <h3 className="center">Document Uploaded! Ready to Print!</h3>
                            <span className="center">"Your photos are ready to shine! Print them now."</span>
                            <span className="center" style={{ color: 'red' }}>{stateValue}</span>
                        </>
                    }
                    <div className="congo-img center">
                        <img src={Check} alt="check" />
                    </div>
                    <div className="form-btn-block">
                        <button className="back-btn" onClick={handleClick}>
                        Back to home</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Congratulation;